import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCashTracker, getCashTrackerReport } from "../../Services/api";
import instance from "../../redux/api";
import {
  getAccountsList,
  getBrokerBranchesList,
  getCurrencyList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import { ViewAndEditBankTransaction } from "./ViewAndEditTxnDialog";
import { NAVIGATION_PAGE_NAME } from "../../constants";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { CommonButton } from "../common/commonButton";
import format from "date-fns/format";
import { FormLabel, Grid, Input, Stack, TextField } from "@mui/material";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { numberFormatter } from "../../utils/generalFunctions";
import { toast } from "react-toastify";
import { allocationStatusList } from "../CashAllocation/constants";
import { getPaginationCount } from "../CashAllocation/utils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomDateRangePicker from "../common/CustomDateRangePicker"

//styles
const CashTrackerText = {
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "400",
  marginTop: 5,
};

export default function CashTracker() {
  const { search } = useLocation();

  const toggle = useSelector((state) => state.toggleSideMenu);

  const dispatch = useDispatch();
  const location = useLocation();
  // const navigate = useNavigate();

  // const [searchText, setSearchText] = useState("");
  // const [tableRows, setTableRows] = useState([]);
  // const [filteredRows, setFilteredRows] = useState(tableRows);
  const [currentRow, setCurrentRow] = useState("");
  const [txnMode, setTxnMode] = useState("VIEW");
  const [isOpenTxnDialog, setIsOpenTxnDialog] = useState(false);

  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  //styles
  const classes = useStyles();

  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Bank_Name: "",
    Bank_Policy_No: "",
    allocated_date: "",
    Bank_Transaction_Id: "",
    Bank_Allocation_Status: "",
    Bank_Reference_No: "",
  });

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [isStatusDeSelected, setIsStatusDeSelected] = useState(false);

  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [dialogCommentsValue, setDialogCommentsValue] = useState(null);

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleCommentsOpenDialog = (currentRowCommentsData) => {
    setOpenCommentsDialog(true);
    setDialogCommentsValue(currentRowCommentsData);
  };

  const handleCloseDialogs = () => {
    setOpenCommentsDialog(false);
    setDialogCommentsValue(null);
  };

  const columns = [
    {
      field: "historical",
      headerName: "",
      width: 15,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params?.row?.historical === true ? (
            <div className="cash-alloc-indicator historical"></div>
          ) : (
            <div className="cash-alloc-indicator non-historical"></div>
          )}
        </>
      )
    },
    {
      field: "Real_Bank_Name",
      headerName: "Bank Name",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "Bank_Transaction_Id",
      headerName: "TXN ID",
      width: 120,
      headerAlign: "center",
      renderCell: (params) => (
        <a
          href={`javascript:void(0)`}
          onClick={() =>
            handleActionButton(
              {
                ...params.row.bank_txn,
                Real_Bank_Name: params.row.Real_Bank_Name,
              },
              "VIEW"
            )
          }
          // target="_blank"
          // rel="noopener noreferrer"
          style={{ color: "blue", cursor: "pointer" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "Receivable_Amount",
      headerName: "Receivable Amt",
      width: 120,
      headerAlign: "center",
      // renderCell: (params) => (
      //   <p
      //   // href={`javascript:void(0)`}
      //   // onClick={() => console.log("fsf",params.row.bank_txn)}
      //   // onClick={() => handleActionButton(params.row.bank_txn, "VIEW")}
      //   // target="_blank"
      //   // rel="noopener noreferrer"
      //   // style={{ color: "blue" }}
      //   >
      //     {params.row.bank_txn.Receivable_Amount}
      //   </p>
      // ),

      renderCell: (params) =>
        numberFormatter(params?.row?.bank_txn?.Receivable_Amount),
      cellClassName: "amount-table-cell"
    },
    {
      field: "Broker_Branch",
      headerName: "Broker Branch",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "Policy_Line_Ref",
      headerName: "Policy#",
      width: 120,
      headerAlign: "center",
      renderCell: (params) => (
        <a
          href={`javascript:void(0)`}
          onClick={() =>
            (window.location.href = `/cash-allocation?txn_id=${params?.row?.Bank_Transaction_Id}&policy_ref=${params?.row?.id}`)
          }
          rel="noopener noreferrer"
          role="button"
          style={{ color: "blue" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "assigned_date",
      headerName: "Allocated Date",
      width: 160,
      headerAlign: "center",
      valueGetter: (params) => params?.row?.bank_txn?.assigned_date,
      valueFormatter: (params) => {
        return params?.value ? format(new Date(params?.value), "dd-MM-yyyy") : "";
      },
    },
    {
      field: "allocation_status",
      headerName: "Status",
      width: 160,
      headerAlign: "center",
      // valueGetter: (params) => params?.row?.bank_txn?.transaction_status,
    },
    {
      field: "bank_curr",
      headerName: "Currency",
      width: 75,
      headerAlign: "center",
    },
    // {
    //   field: "allocated_amt",
    //   headerName: "Allocated Amount",
    //   headerAlign: "center",
    //   width: 160,
    //   valueFormatter: (params) => {
    //     return numberFormatter(params.value)
    //   }
    // },
    {
      field: "XFIbatchno",
      headerName: "Batch Reference",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "cashreference",
      headerName: "Cash Reference",
      headerAlign: "center",
      width: 120,
      valueGetter: (params) => params?.row?.cashreference,
      renderCell: (params) => <p>{params?.row?.cashreference ?? ""}</p>,
    },
    {
      field: "GXPbatchno",
      headerName: "GXB Reference",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "Assigned_User",
      headerName: "Assigned To",
      headerAlign: "center",
      width: 120,
      valueGetter: (params) => params?.row?.bank_txn?.Assigned_User,
      renderCell: (params) => {
        return (
          <>
            {
              params?.row?.bank_txn?.Assigned_User ?? ""
            }
          </>
        )
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      headerAlign: "center",
      width: 130,
      renderCell: (params) => (
        <>
          {params?.row?.comment ? (
            <>
              {params?.row?.comment?.substr(0, 10)}
              <MoreHorizIcon
                onClick={() => handleCommentsOpenDialog(params?.row?.comment)}
              />
            </>
          ) : (
            <>{""}</>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setPaginationCount(
      getPaginationCount(pageState?.total, pageState?.pageSize)
    );
  }, [pageState?.total]);

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
        }`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      } else {
        // If we get information while performing route navigation
        if (!isClearPerformed) {
          let allocated = location?.state?.allocated || "";
          if (allocated) {
            queryParams = `${queryParams}&allocated=${allocated}`;
          }
          const policyNo = new URLSearchParams(search).get("policyNo");
          if (policyNo) {
            queryParams = `${queryParams}&policyNumber=${policyNo}`;
            setSearchFieldsPrams({
              ...searchFieldsPrams,
              Bank_Policy_No: policyNo,
            });
          }
        }
      }

      const response = await instance.get(`${getCashTracker}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleCashTrackerFieldsInputChange = (name, value, reason) => {
    if (!value) {
      handleClearSearchList();
    }
    setSearchFieldsPrams({
      ...searchFieldsPrams,
      [name]: value?.trim(),
    });
    setIsStatusDeSelected(false);
    if (reason == "clear") {
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        Bank_Allocation_Status: "",
      });
      setIsStatusDeSelected(true);
    }
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const {
      Bank_Name,
      Bank_Policy_No,
      Bank_Transaction_Id,
      Bank_Allocation_Status,
      Bank_Reference_No,
    } = searchFieldsPrams;

    if (Bank_Name) {
      getSearchQueryParams = `${getSearchQueryParams}&bankName=${Bank_Name}`;
    }

    if (Bank_Policy_No) {
      getSearchQueryParams = `${getSearchQueryParams}&policyNumber=${Bank_Policy_No}`;
    }

    if (Bank_Transaction_Id) {
      getSearchQueryParams = `${getSearchQueryParams}&transactionId=${Bank_Transaction_Id}`;
    }

    if (Bank_Allocation_Status) {
      getSearchQueryParams = `${getSearchQueryParams}&status=${Bank_Allocation_Status}`;
    }

    if (Bank_Reference_No) {
      getSearchQueryParams = `${getSearchQueryParams}&searchKey=${Bank_Reference_No}`;
    }

    let formattedFromDateValue = "",
      formattedToDateValue = "";

    if (fromDateValue && toDateValue) {
      formattedFromDateValue = format(fromDateValue, "yyyy-MM-dd");
      formattedToDateValue = format(toDateValue, "yyyy-MM-dd");
      getSearchQueryParams = `${getSearchQueryParams}&fromAllocationDate=${formattedFromDateValue}&toAllocationDate=${formattedToDateValue}`;
    }
    return getSearchQueryParams;
  };

  const handleSearchBankTransactionList = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      let allocated = location?.state?.allocated || "";
      if (allocated) {
        queryParams = `${queryParams}&allocated=${allocated}`;
      }

      if (location?.state?.fromDateValue && location?.state?.toDateValue && !queryParams.includes("fromAllocationDate")) {
        let formattedFromDateValue = format(location?.state?.fromDateValue, "yyyy-MM-dd");
        let formattedToDateValue = format(location?.state?.toDateValue, "yyyy-MM-dd");
        queryParams = `${queryParams}&fromAllocationDate=${formattedFromDateValue}&toAllocationDate=${formattedToDateValue}`;
      }

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${getCashTracker}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1,
        }));
        setIsSearchPerformed(true);
        setIsClearPerformed(false);
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPaginationCount(0);
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      console.log("error", error);
    }
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const {
      Bank_Name,
      Bank_Policy_No,
      Bank_Transaction_Id,
      Bank_Allocation_Status,
      Bank_Reference_No,
    } = searchFieldsPrams;
    if (
      Bank_Name !== "" ||
      Bank_Policy_No !== "" ||
      Bank_Transaction_Id !== "" ||
      Bank_Allocation_Status !== "" ||
      Bank_Reference_No !== "" ||
      fromDateValue !== "" ||
      toDateValue !== ""

    ) {
      disabled = false;
    }
    return disabled;
  };

  const handleClearSearchList = async () => {
    setIsClearPerformed(true);
    setIsSearchPerformed(false);
    // clear fields
    setSearchFieldsPrams({
      Bank_Name: "",
      Bank_Policy_No: "",
      Bank_Transaction_Id: "",
      Bank_Allocation_Status: "",
      Bank_Reference_No: "",
    });
    setFromDateValue("");
    setToDateValue("");
  };

  const handleDownload = async () => {
    setIsDownloadInProgress(true);
    try {
      instance
      .get(`${getCashTrackerReport}`, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Cash Tracker ${format(new Date(), "dd-MM-yyyy")}`
        link.setAttribute("download", `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsDownloadInProgress(false);
        toast.success("File has been downloaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }).catch((err) => {
        console.log(err);
        setIsDownloadInProgress(false);
        toast.error("File could not be downloaded", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    } catch (error) {
      setIsDownloadInProgress(false);
      toast.error("File could not be downloaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const [isBankNameSelected, setIsBankNameSelected] = useState(false);

  // Handler for Bank Name Dropdown
  const handleBankNameDropdownChange = (name, value, reason) => {
    if (reason === "clear") {
      setIsBankNameSelected(false);
      setSearchFieldsPrams(prev => ({ ...prev, [name]: value, Receiving_Bank_Account: null }));
    } else {
      setIsBankNameSelected(true);
      setSearchFieldsPrams(prev => ({ ...prev, [name]: value }));
    }
  };

  const [banksList, setBanksList] = useState([])

  const getBanksList = async () => {
    const response = await instance.get("documents/get_all_unique_bank_names/")
    setBanksList(response?.data?.bank_names)
  }

  useEffect(() => {
    getBanksList()
  }, [])

  useEffect(() => {
    // Fetch Banks List
    dispatch(getAccountsList());
    // Fetch Broker branches
    dispatch(getBrokerBranchesList());
    // Fetch Currency Details
    dispatch(getCurrencyList());

    // load data
    loadData();

    // Reload data when clear is performed or pagination changes
    if (isClearPerformed) {
      loadData();
    }
  }, [isClearPerformed, pageState?.pageSize, pageState?.page]);

  const handleActionButton = (currentRowData, currentTxnMode) => {
    setCurrentRow(currentRowData);
    setTxnMode(currentTxnMode);
    setIsOpenTxnDialog(true);
  };

  return (
    <div style={Container}>
      <div>
        <div style={CashTrackerText}>
          <span style={{ color: "#FF5A01", fontSize: "24px" }}>
            Cash Tracker
          </span>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
              <span className="cash-alloc-indicator historical"
                style={{ marginRight: "3px" }} />
              <span>Historical</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="cash-alloc-indicator non-historical"
                style={{ marginRight: "3px" }}
              />
              <span>Non-Historical</span>
            </div>
          </div>
        </div>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: "5px",
          }}
          xs={12}
          gap={"10px"}
        >
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
            xs={1.38}
          >
            <FormLabel>Bank Name</FormLabel>
            <CommonSelect
              placeholder="Select Bank Name"
              value={searchFieldsPrams?.Bank_Name ?? ""}
              handleChange={(event, value, reason) =>
                handleBankNameDropdownChange(
                  "Bank_Name",
                  value,
                  reason,
                  event
                )
              }
              options={ banksList || [] }
            />
          </Grid>
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
            xs={1.38}
          >
            <FormLabel>Policy No</FormLabel>
            <TextField
              value={searchFieldsPrams?.Bank_Policy_No}
              onChange={(e) =>
                handleCashTrackerFieldsInputChange(
                  "Bank_Policy_No",
                  e.target.value
                )
              }
              size="small"
            />
          </Grid>
          <Grid
            item
            xs={1.38}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Transaction Id</FormLabel>
            <TextField
              value={searchFieldsPrams?.Bank_Transaction_Id}
              onChange={(e) =>
                handleCashTrackerFieldsInputChange(
                  "Bank_Transaction_Id",
                  e.target.value
                )
              }
              sx={{ width: "auto" }}
              size="small"
            />
          </Grid>
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
            xs={1.38}
          >
            <FormLabel>Status</FormLabel>
            <CommonSelect
              placeholder="Select Status"
              value={searchFieldsPrams?.Bank_Allocation_Status ?? ""}
              handleChange={(event, value, reason) =>
                handleCashTrackerFieldsInputChange(
                  "Bank_Allocation_Status",
                  value,
                  reason,
                  event
                )
              }
              options={
                allocationStatusList?.map((option) => option?.label) || []
              }
            />
          </Grid>
          <Grid
            item
            xs={1.38}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Reference No</FormLabel>
            <TextField
              value={searchFieldsPrams?.Bank_Reference_No}
              onChange={(e) =>
                handleCashTrackerFieldsInputChange(
                  "Bank_Reference_No",
                  e.target.value
                )
              }
              sx={{ width: "auto" }}
              size="small"
            />
          </Grid>
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
            xs={1.7}
          >
            <FormLabel>Allocated Date</FormLabel>
            <CustomDateRangePicker
              startDate={fromDateValue}
              setStartDate={setFromDateValue}
              endDate={toDateValue}
              setEndDate={setToDateValue}
              maxDate={new Date()}
            />
          </Grid>
          <Grid
            item
            xs={1.38}
            display={"flex"}
            flexDirection={"row"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={handleSearchBankTransactionList}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />

            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            xs={1.38}
            display={"flex-end"}
            flexDirection={"row"}
            mt={"20px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text={ isDownloadInProgress ? "Downloading" : "Download"}
              hoverColor="#FF5A01"
              icon={<DownloadIcon />}
              handleClick={handleDownload}
              disabled={isDownloadInProgress}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          height: "calc(100vh - 296px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(
                getPaginationCount(pageState?.total, newPageSize)
              );
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          sx={{
            "& .css-123glz5-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
              color: "black !important",
            },
            "& .MuiDataGrid-columnHeader[data-field='Assigned_Users']": {
              minWidth: toggle?.isOpen
                ? "160.137px !important"
                : "160.137px !important",
            },
          }}
          // disableColumnFilter
          // disableColumnSelector
          // disableDensitySelector
          // disableColumnMenu
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      {isOpenTxnDialog && (
        <ViewAndEditBankTransaction
          isOpenTxnDialog={isOpenTxnDialog}
          setIsOpenTxnDialog={setIsOpenTxnDialog}
          txnMode={txnMode}
          txnData={currentRow}
          navigatedFrom={NAVIGATION_PAGE_NAME.CASH_TRACKER}
        />
      )}

      <Dialog
        open={openCommentsDialog}
        onClose={handleCloseDialogs}
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          Comment
          <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseDialogs();
            }}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon className="CloseIconStyle" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogCommentsValue}
            className=""
            disabled={true}
            style={{ width: "350px", minWidth: "350px" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
