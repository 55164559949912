import React from "react";
import {
    CircularProgress,
    Box,
} from "@mui/material";
import { padding } from "@mui/system";

export const CustomLoader = () => {
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: 20
            }}
        >
            <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} />
        </Box>
    );
}
